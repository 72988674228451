import Script from "next/script"
import { useEffect, useState } from "react"

declare const window: any
declare const kpUpdateDOM: any

export default function ({ onLoaded }: any) {
    const [isMerchantInfoSet, setIsMerchantInfoSet] = useState(false)
    const onReady = () => {
        if (typeof kpUpdateDOM !== 'undefined') {
            kpUpdateDOM();
        }

        if (onLoaded) onLoaded();
    }

    useEffect(() => {
        window.merchantInfo = {
            environment: 'sandbox',
            mid: '3mt5u72gdtkr4wgs8y',
            type: 'merchantInfo',
        };
        setIsMerchantInfoSet(true)
        // window.Shopify = {
        //     shop: window.origin,
        // };
    }, [])

    return (
        <>
            <Script id="non-shopify-core-functions" src="https://sandbox.pdp.gokwik.co/kwikpass/non-shopify-core-functions.min.js" />
            {isMerchantInfoSet && <Script id="merchant-v2" src="https://sandbox.pdp.gokwik.co/kwikpass/plugin/build/kp-merchant-v2.js" strategy="afterInteractive" onReady={onReady} />}
        </>
    )
}