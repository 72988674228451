import { useCallback, useEffect, useState } from 'react';
import KwikPassScript from '@components/KwikPass/KwikPassScript'
import { EmptyGuid } from '@components/utils/constants';
import { setSessionItem } from '@components/utils/sessionStorage';
import { useRouter } from 'next/router';
import { LocalStorage } from '@components/utils/payment-constants';
import Cookies from 'js-cookie';
import { setItem } from '@components/utils/localStorage';
import { getDeviceTypeString } from '@commerce/utils/use-device'
import { v4 as uuid } from 'uuid'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'

export default function KwikPass() {
    const router = useRouter()
    const trackMoEngageEvent = useMoEngageEvent()
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const onLoaded = useCallback(() => {
        setScriptLoaded(true)
    }, [])

    const handleKwikpassLogin = (event: any) => {
        if(event?.detail?.['gk-access-token']) setItem('goKwikAccessToken', event?.detail?.['gk-access-token'])
    };

    const handleMerchantLogin = (event: any) => {
        if(event?.detail?.userId && event?.detail?.userId !== EmptyGuid && event?.detail?.username) {
            const {userToken, ...rest } = event?.detail
            setItem('user', {...rest})
            if(userToken && userToken?.access_token){
                localStorage.setItem(LocalStorage.Key.userEncryptedDetails, userToken?.access_token)
                  Cookies.set(LocalStorage.Key.userEncryptedDetails, userToken?.access_token)
            }
            setSessionItem('isUserAlreadyExist', JSON.stringify(false))
            
            trackMoEngageEvent(
                MO_ENGAGE_EVENT.LOGIN,
                {
                device: getDeviceTypeString(),
                first_login:'',
                status: "sent",
                mobile_number: event?.detail?.username,
                uuid : uuid()
                },
                window
            )
            router.push('/checkout')    
        }
        if(event?.detail?.recordId && event?.detail?.isValid) {
          // handle first time user create
        }
    };

    useEffect(() => {

        if (scriptLoaded) {
            window.addEventListener('user-loggedin', handleKwikpassLogin);
            window.addEventListener('user_loggedin_merchant', handleMerchantLogin);

            // Cleanup function
            return () => {
                window.removeEventListener('user-loggedin', handleKwikpassLogin);
                window.removeEventListener('user_loggedin_merchant', handleMerchantLogin);
            };
        }
    }, [scriptLoaded]);

    return (
        <KwikPassScript onLoaded={onLoaded} />
    );
}